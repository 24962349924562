import Modal from 'components/modal';
import React from 'react';
import * as Styles from './styles';
import success from 'assets/icons/svg/success.svg';
const SuccessModal = ({ open, toggle }) => {
  return (
    <Modal open={open} toggle={toggle} title={'Success'}>
      <Styles.Success className="py-2 px-2 mt-4">
        <img src={success} className="icon" />
        <div className="mx-3">
          <span className="small-font">You have successfully created a </span>
          <p className="p-0 m-0">Classic NFT</p>
        </div>
      </Styles.Success>
    </Modal>
  );
};

export { SuccessModal };
