import React from 'react';
import * as Styles from 'components/template/styles';
import arrow from 'assets/icons/svg/arrow.svg';
import { Collapse } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { Menu } from './index';

const MenuData = ({ collapsed, icon, name, items, path, customClass, paths }) => {
  const location = useLocation();
  const active = paths ? paths.includes(location.pathname) : location.pathname === path;
  const [isChecked, setIsChecked] = React.useState(false);

  const toggle = () => setIsChecked((prev) => !prev);
  return (
    <Styles.Menu className={`${active && 'active'}`} collapsed={collapsed} active={active}>
      <div className={`d-flex justify-content-between py-3`} onClick={toggle}>
        <div
          className={`d-flex ${collapsed ? 'justify-content-center' : 'justify-content-between'} w-100  ${customClass}`}
        >
          <div className="d-flex">
            <div className="icon mx-2">{icon}</div>
            {!collapsed && <p className="m-0">{name}</p>}
          </div>
          {!collapsed && (
            <React.Fragment>
              {items.length ? (
                <div className="mx-3">
                  <img src={arrow} className="children-icon" />{' '}
                </div>
              ) : null}
            </React.Fragment>
          )}
        </div>
        {active && !collapsed && (
          <div style={{ marginRight: '-3px' }}>
            <img src={arrow} className="active-icon" />
          </div>
        )}
      </div>
      {items.length ? (
        <Collapse in={isChecked}>
          <div className="mt-3">
            {items.map((item, index) => (
              <Menu
                key={index}
                name={item.name}
                icon={<img src={item.icon} />}
                items={item.children}
                collapsed={collapsed}
                path={item.path}
                customClass="px-3"
              />
            ))}
          </div>
        </Collapse>
      ) : null}
    </Styles.Menu>
  );
};

export { MenuData };
