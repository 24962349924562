// import { TextInput } from 'components/text-input/styles';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import lightTheme from 'themes/light-theme';

const useStyles = makeStyles({
  root: {
    marginTop: 20,
    '@media (max-width: 768px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },

  btn: {
    padding: '4px 24px',
    backgroundColor: lightTheme.primary,
    border: '2px solid',
    borderColor: lightTheme.primary,
    'box-sizing': 'border-box',
    borderRadius: 14,
    color: 'white',
    marginRight: 5,
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center',
    // alignItems: 'center',
    cursor: 'pointer',
  },
  input: {
    height: 50,
    borderWidth: '0.3px',
    border: '4px solid #171C28',
    borderRadius: '12px',
    fontSize: '20px',
    width: '45%',
    margin: 10,
    padding: 10,
    '@media (max-width: 768px)': {
      width: 280,
    },
  },
});

const CardForm = ({ setIsLock }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={8}>
        <input type="text" className={classes.input} placeholder="Price" />
        <input type="text" className={classes.input} placeholder="Name" />
        <input type="text" className={classes.input} placeholder="Symbol" />
        <input type="text" className={classes.input} placeholder="Total Supply" />
      </Grid>
      <Grid item md={4} className="d-flex align-items-end mb-2">
        <button className={classes.btn} style={{ color: 'black', backgroundColor: 'white' }}>
          Cancel
        </button>
        <button
          className={classes.btn}
          style={{ color: 'white', backgroundColor: 'black' }}
          onClick={() => setIsLock(false)}
        >
          Confirm
        </button>
      </Grid>
    </Grid>
  );
};

export { CardForm };
