import styled from 'styled-components';

export const Type = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  background: ${(props) => props.theme.primary3Shade};
  border: 0.2px solid;
  border-color: ${(props) => props.theme.primary3Shade};
  &:hover {
    border: 0.2px solid white;
  }
  box-sizing: border-box;
  border-radius: 10px;

  h6 {
    margin: 0px;
  }
  .icon {
    display: flex;
    width: 30px;
    height: 30px;
  }
  .subtitle {
    font-size: 10px;
    margin: 0;
  }
`;

export const Initializing = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background: ${(props) => props.theme.primary2Shade};
  border-radius: 10px;
  .loader {
    border: 1.8px solid grey;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    border-top: 1.8px solid white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ErrorInitializing = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  background: ${(props) => props.theme.primary2Shade};
  border-radius: 10px;
  border: 0.3px solid;
  border-color: ${(props) => props.theme.error};
  p {
    margin: 0;
    color: ${(props) => props.theme.error};
    font-weight: 500;
  }
  img {
    width: 18px;
    height: 18px;
  }
`;

export const WhiteButton = styled.div`
  border-radius: 8px;
  font-weight: 500;
  line-height: 30px;
  background-color: white;
  cursor: pointer;
  color: black;
  padding: 0px 10px;

  &.change-button {
    line-height: 20px;
  }
  &.error-button {
    line-height: 30px;
    font-weight: 400;
  }
`;

export const Connected = styled.div`
  font-size: 12px;
  .connected-wallet {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    background: ${(props) => props.theme.primary2Shade};
    border-radius: 10px;

    .second-row {
      display: flex;
      align-items: flex-start;
      align-self: flex-start;
      span {
        margin: 0;
      }
    }
    .third-row {
      align-self: flex-start;
      display: flex;
      span {
        margin: 0px 5px;
        color: ${(props) => props.theme.gray};
      }
      img {
        width: 12px;
        height: 12px;
      }
    }
  }
  .transactions {
    cursor: pointer;
    background: ${(props) => props.theme.primary3Shade};
    border-radius: 10px;
    font-size: 12px;
  }
  .swap {
    color: ${(props) => props.theme.blue};
    img {
      width: 8px;
      height: 8px;
    }
  }
`;

export const TransactionConfirming = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .confirm {
    cursor: pointer;
    background: ${(props) => props.theme.primary3Shade};
    border-radius: 10px;
    font-size: 12px;
  }
`;

export const TransactionError = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h5 {
    color: ${(props) => props.theme.error};
    font-weight: 500;
  }
  .dismiss {
    font-size: 14px;
  }
  img {
    width: 70px;
    height: 70px;
  }
`;

export const TransactionSubmitted = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 70px;
    height: 70px;
  }
  h5 {
    font-weight: 500;
    line-height: 35px;
  }
  .view {
    color: ${(props) => props.theme.blue};
    font-size: 12px;
    cursor: pointer;
  }
  .confirm {
    cursor: pointer;
    background: ${(props) => props.theme.primary3Shade};
    border-radius: 10px;
    font-size: 12px;
  }
  .add-token {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #fafbff;
    border-radius: 13px;
    padding: 5px 20px;
    .icon {
      img {
        height: 28px;
        width: 28px;
        margin-left: 10px;
      }
    }
  }
`;
