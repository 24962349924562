import React from 'react';
import { makeStyles } from '@material-ui/core';
import theme from 'themes/light-theme';
import { typesData } from 'constants/profile';

const useStyles = makeStyles({
  btn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderRadius: 22,
    height: 35,
    backgroundColor: 'white',
    marginTop: -8,
    padding: '20px 0px 20px 10px',
    border: '1px solid #E9E9EE',
  },
  type: {
    color: theme.primary,
    margin: '0px 6px',
  },
  iconWrapper: {
    padding: '7px 10px',
    border: '1px solid #E9E9EE',
    borderRadius: 22,
  },
});

const Label = ({ type }) => {
  const classes = useStyles();

  return (
    <button className={classes.btn}>
      <p className={`p-0 ${classes.type}`}>{type}</p>
      <div className={classes.iconWrapper}>
        <img src={typesData[type].icon} height="20" width="20" />
      </div>
    </button>
  );
};

export { Label };
