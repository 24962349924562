import React from 'react';
import { Grid } from '@material-ui/core';
import CustomCard from 'components/custom-card';
import { handleData } from 'constants/profile';

const CardContainer = ({ type }) => {
  return (
    <Grid container wrap="wrap" justifyContent="center">
      {handleData(type).map((card, index) => (
        <Grid item key={index}>
          <CustomCard image={card.image} text={card.text} id={card.id} type={card.type} isViewMore />
        </Grid>
      ))}
    </Grid>
  );
};

export { CardContainer };
