import React from 'react';
import * as Styles from 'components/connect-wallet/styles';
import Loader from 'components/loader';

const TransactionConfirming = ({ confirmTransaction }) => {
  return (
    <Styles.TransactionConfirming className="mt-4">
      <Loader size={60} />
      <h5 className="my-2">Waiting for confirmation</h5>
      <div className="confirm w-100 py-3 px-2 mt-3" onClick={confirmTransaction}>
        Confirm this transaction in your wallet
      </div>
    </Styles.TransactionConfirming>
  );
};

export { TransactionConfirming };
