import styled from 'styled-components';

export const SidebarContainer = styled.div`
  z-index: 1;
  height: 93.6vh;
  @media screen and (max-width: 600px) {
    height: 100vh;
  }
  background-color: ${(props) => props.theme.primary};
  color: white;
  position: ${(props) => (props.matchesMobile ? 'absolute' : 'initial')};
  width: ${(props) =>
    props.matchesMobile && props.collapsed
      ? '0px'
      : props.matchesMobile
      ? '250px'
      : props.collapsed
      ? '80px'
      : '328px'};
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s all;
`;

export const Header = styled.div`
  margin-bottom: 30px;
  .icon {
    display: flex;
    justify-content: ${(props) => (props.collapsed ? 'center' : 'flex-end')};
    align-items: ${(props) => (props.collapsed ? 'center' : 'flex-end')};
    cursor: pointer;
  }
  hr {
    opacity: 1;
    height: 1.3px;
  }
`;

export const Menu = styled.div`
  background-color: ${(props) => props.theme.primary3Shade};
  cursor: pointer;
  &.active {
    background-color: ${(props) => props.theme.primary2Shade};
  }
  .icon {
    display: flex;
    cursor: pointer;
  }
  hr {
    opacity: 1;
    height: 1.3px;
  }
  .children-icon {
    transform: rotate(180deg);
  }
  .active-icon {
    transform: rotate(270deg);
  }
  img {
    width: ${(props) => (props.collapsed ? '20px' : '14px')};
  }
`;

export const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
  .brand-icon {
    width: 100px;
  }
  .icon {
    cursor: pointer;
  }
  filter: ${(props) => (props.blur ? 'blur(1px)' : '')};
  background-color: ${(props) => (props.blur ? 'grey' : '')}; ;
`;

export const children = styled.div`
  filter: ${(props) => (props.blur ? 'blur(1px)' : '')};
  background-color: ${(props) => (props.blur ? 'grey' : '')};
  overflow: auto;
`;
