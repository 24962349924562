import React from 'react';
import lockOutline from 'assets/icons/svg/lock-outline.svg';
import lockOutlinePrimary from 'assets/icons/svg/lock-outline-primary.svg';
import whiteListed from 'assets/images/whiteListed.svg';

const LockList = ({ classes, isLock, setIsLock }) => {
  return (
    <React.Fragment>
      <div className={classes.btn} onClick={() => setIsLock(true)}>
        <img src={isLock ? lockOutline : lockOutlinePrimary} height="15px" width="15px" className="m-0.5" />{' '}
        <p className="m-0 px-2">Lock</p>
      </div>
      <div className={classes.btn}>
        <img src={whiteListed} height="15px" width="15px" className="m-0.5" /> <p className="m-0 px-2">List</p>
      </div>
    </React.Fragment>
  );
};

export { LockList };
