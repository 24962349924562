import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring/dist/react-spring.cjs'; // web.cjs is required for IE 11 support
import OurTheme from 'themes/light-theme';
import cross from 'assets/icons/svg/cross.svg';
import prevIcon from 'assets/icons/svg/prev-icon.svg';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: OurTheme.primary,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 1),
    color: 'white',
    width: '350px',
    borderRadius: '13px',
    outline: 'none',
  },
  hr: {
    opacity: 1,
    color: 'white',
    padding: 0,
    margin: '10px 0px 10px 0px',
  },
  crossIcon: {
    cursor: 'pointer',
    marginBottom: '0.5rem',
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function SpringModal({ toggle, open, children, title, prevButton, prevButtonClick }) {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={open}
      onClose={toggle}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className="d-flex justify-content-between align-items-center">
            {prevButton ? (
              <img src={prevIcon} className={classes.crossIcon} onClick={prevButtonClick} />
            ) : (
              <h6 id={`spring-modal-title`}>{title}</h6>
            )}
            <img src={cross} onClick={toggle} className={classes.crossIcon} />
          </div>
          <hr className={classes.hr} />
          <p id="spring-modal-description">{children}</p>
        </div>
      </Fade>
    </Modal>
  );
}
