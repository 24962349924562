import React, { useState } from 'react';
import * as Styles from 'components/template/styles';
import { Menu } from '@material-ui/icons';
import wrapIt from 'assets/icons/svg/wrapit.svg';
import CustomButton from 'components/button';
import ConnectWallet from 'components/connect-wallet';
const NavBar = ({ toggleCollapse, matchesMobile, blur }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prev) => !prev);
  return (
    <Styles.NavBar className="px-4 py-3" blur={blur}>
      <div className="d-flex">
        {matchesMobile && (
          <div className="icon mx-2" onClick={toggleCollapse}>
            <Menu fontSize="large" />
          </div>
        )}
        <img src={wrapIt} className="brand-icon" />
      </div>
      <ConnectWallet open={open} toggle={toggle} />
      <CustomButton onClick={toggle} borderRadius="7px">
        Connect Wallet
      </CustomButton>
    </Styles.NavBar>
  );
};

export default NavBar;
