import styled from 'styled-components';

export const TextInput = styled.input`
  border-color: ${(props) => props.theme.primary};
  border-width: 0.3px;
  outline: none;
  border-radius: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  ::placeholder {
    opacity: 0.7;
  }
  max-width: 300px;
  width: ${(props) => props.width || '30%'};
  min-width: 150px;
`;
