import styled from 'styled-components';

export const Success = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  background: ${(props) => props.theme.primary3Shade};
  border: 0.2px solid;
  border-color: ${(props) => props.theme.primary3Shade};
  box-sizing: border-box;
  border-radius: 10px;

  .small-font {
    font-size: 12px;
  }
  .icon {
    display: flex;
    width: 30px;
    height: 30px;
  }
  .subtitle {
    font-size: 10px;
    margin: 0;
  }
`;
