import React from 'react';
import { Balance } from './index';
import { Grid, useMediaQuery } from '@material-ui/core';
import { balanceDetail } from 'constants/reimburser';
import * as Styles from 'components/reimburser/styles';
const Details = () => {
  const matchesMobile = useMediaQuery('(max-width:600px)');
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {balanceDetail.map((item, index) => (
          <Grid
            item
            xl={3}
            lg={3}
            md={4}
            sm={6}
            xs={6}
            key={index}
            className="d-flex align-items-center justify-content-center"
          >
            <Balance
              price={item.price}
              subtitle={item.subtitle}
              icon={<img src={item.icon} />}
              matchesMobile={matchesMobile}
            />
            {!matchesMobile && <Styles.horizontalLine display={index === balanceDetail.length - 1} />}
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};

export { Details };
