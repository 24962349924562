import React from 'react';
import * as Styles from 'components/connect-wallet/styles';
import submitting from 'assets/icons/svg/transaction-submitted.svg';
const TransactionSubmitted = ({ selectedWallet, toggle }) => {
  return (
    <Styles.TransactionSubmitted>
      <img src={submitting} className="mb-1" />
      <h5 className="my-0">Transaction submitted</h5>
      <p className="view">View on Explorer</p>
      <div className="add-token">
        <p className="m-0 p-0 text-center">Add TOKEN to {selectedWallet.name}</p>{' '}
        <span className="icon">{selectedWallet.icon}</span>
      </div>
      <Styles.WhiteButton className="px-4 mt-3" onClick={toggle}>
        Close
      </Styles.WhiteButton>
    </Styles.TransactionSubmitted>
  );
};

export { TransactionSubmitted };
