import React, { useEffect, useState } from 'react';
import SpringModal from 'components/modal';
import {
  ErrorInitializing,
  Type,
  TypeDetails,
  Initializing,
  Connected,
  TransactionConfirming,
  TransactionError,
  TransactionSubmitted,
} from './components';
import { modalTitles, steps, types } from 'constants/wallet';

const ConnectWallet = ({ toggle, open }) => {
  const [activeStep, setActiveStep] = useState(steps.WALLET);
  const [wallet, setWallet] = useState();
  const selectedWallet = types.find((item) => item.name === wallet);
  const confirmTransaction = () => {
    setActiveStep(steps.TRANSACTION_CONFIRMED_OPENED);
  };
  useEffect(() => {
    if (!open) {
      setActiveStep(steps.WALLET);
      setWallet();
    }
  }, [open]);
  return (
    <div>
      <SpringModal
        toggle={toggle}
        open={open}
        title={modalTitles[activeStep]}
        prevButton={activeStep === steps.INITIALIZING}
        prevButtonClick={() => {
          setActiveStep(steps.WALLET);
          setWallet();
        }}
      >
        {activeStep === steps.INITIALIZING && (
          <React.Fragment>
            <ErrorInitializing
              onClick={() => {
                setActiveStep(steps.TRANSACTION_ERROR);
              }}
            />{' '}
            <Initializing
              onClick={() => {
                setActiveStep(steps.TRANSACTION_SUBMITTED);
              }}
            />
            <Type
              name={selectedWallet.name}
              icon={selectedWallet.icon}
              isSubtitle
              onClick={() => {
                setActiveStep(steps.CONNECTED);
              }}
            />
          </React.Fragment>
        )}
        {(activeStep === steps.TRANSACTION_CONFIRMED_OPENED || activeStep === steps.CONNECTED) && (
          <Connected
            activeStep={activeStep}
            wallet={selectedWallet}
            setActiveStep={setActiveStep}
            opened={activeStep === steps.TRANSACTION_CONFIRMED_OPENED}
          />
        )}
        {activeStep === steps.TRANSACTION_SUBMITTED && (
          <TransactionSubmitted selectedWallet={selectedWallet} toggle={toggle} />
        )}
        {activeStep === steps.TRANSACTION_CONFIRMING && (
          <TransactionConfirming confirmTransaction={confirmTransaction} />
        )}
        {activeStep === steps.TRANSACTION_ERROR && <TransactionError setActiveStep={setActiveStep} />}
        {activeStep === steps.WALLET && (
          <TypeDetails setWallet={setWallet} setIsInitializing={() => setActiveStep(steps.INITIALIZING)} />
        )}
      </SpringModal>
    </div>
  );
};

export default ConnectWallet;
