import { Button } from '@material-ui/core';
import React from 'react';
import * as Styles from './styles';
const CustomButton = ({ color, children, icon, borderRadius, ...remaining }) => {
  return (
    <Styles.Button color={color} borderRadius={borderRadius}>
      <Button variant="contained" {...remaining}>
        {icon && <span className="icon">{icon}</span>} {children}
      </Button>
    </Styles.Button>
  );
};

export default CustomButton;
