import React from 'react';
import * as Styles from 'components/connect-wallet/styles';
import copy from 'assets/icons/svg/copy.svg';
import OpenAddress from 'assets/icons/svg/open-address.svg';
import { steps } from 'constants/wallet';
import arrow from 'assets/icons/svg/icon-arrow-blue.svg';
const Connected = ({ opened, wallet, setActiveStep }) => {
  return (
    <Styles.Connected>
      <div className="connected-wallet px-3 py-2">
        <div className="w-100 d-flex justify-content-between my-1 mx-1">
          <span className="m-0">Connected with {wallet.name}</span>
          <Styles.WhiteButton
            className="change-button"
            onClick={() => {
              setActiveStep(steps.WALLET);
            }}
          >
            Change
          </Styles.WhiteButton>
        </div>
        <div className="second-row my-1">
          <svg width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11" cy="11" r="11" fill="#0073DE" />
          </svg>
          <span className="mx-2">0xde43...6a94</span>
        </div>
        <div className="third-row my-1">
          <div className="d-flex align-items-center mx-1">
            <img src={copy} />
            <span>Copy Address </span>
          </div>{' '}
          <div className="d-flex align-items-center mx-2">
            <img src={OpenAddress} />
            <span>Copy Address </span>
          </div>
        </div>
      </div>
      {!opened && (
        <div
          className="transactions px-4 py-3"
          onClick={() => {
            setActiveStep(steps.TRANSACTION_CONFIRMING);
          }}
        >
          Your transactions will appear here...
        </div>
      )}
      {opened && (
        <div className="transactions px-3 py-1">
          <div className="w-100 d-flex justify-content-between my-1">
            <span className="m-0">Recent Transactions</span>
            <Styles.WhiteButton
              className="change-button"
              onClick={() => {
                setActiveStep(steps.CONNECTED);
              }}
            >
              Clear all
            </Styles.WhiteButton>
          </div>
          <div className="swap mb-4 mt-2 d-flex align-items-center">
            <span className="m-0">Swap 0.01 ETH for 24160000000 DAI on V2</span>
            <img src={arrow} className="mx-2" />
          </div>
        </div>
      )}
    </Styles.Connected>
  );
};

export { Connected };
