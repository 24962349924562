import { useState } from 'react';
import CustomButton from 'components/button';
import TextInput from 'components/text-input';
import showDetails from 'assets/icons/svg/show-details.svg';
import * as yup from 'yup';
import lightTheme from 'themes/light-theme';

let schema = yup.string().email();

const EmailShowDetails = ({ toggleDetails }) => {
  const [error, setError] = useState({});
  const handleEmail = async (e) => {
    const valid = await schema.isValid(e.target.value);
    if (!valid) setError({ email: '*You have entered an invalid address' });
    else setError({ email: false });
  };

  return (
    <div className="w-100 mt-5 d-flex justify-content-center flex-column align-items-center">
      <TextInput placeholder="Enter required address" onBlur={handleEmail} />
      {error.email && <p style={{ color: lightTheme.error }}>{error.email}</p>}
      <CustomButton color="#171C28" icon={<img src={showDetails} />} onClick={toggleDetails}>
        Show Details
      </CustomButton>
    </div>
  );
};

export { EmailShowDetails };
