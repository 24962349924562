import React from 'react';
import * as Styles from 'components/generator/styles';

const FungibleBtn = ({ activeTab, setActiveTab }) => {
  return (
    <div className="d-flex justify-content-center">
      <Styles.Tabs>
        <div className={`first ${activeTab === 'first' && 'active'} px-4`} onClick={() => setActiveTab('first')}>
          <p className="m-0 p-0">Fungible Token</p>
        </div>
        <div className={`second ${activeTab === 'second' && 'active'} px-4`} onClick={() => setActiveTab('second')}>
          <p className="m-0 p-0"> Non-Fungible Token</p>
        </div>
      </Styles.Tabs>
    </div>
  );
};

export { FungibleBtn };
