import React from 'react';
import * as Styles from 'components/connect-wallet/styles';
const Type = ({ name, icon, onClick, isSubtitle }) => {
  return (
    <Styles.Type className="p-3 py-2" onClick={() => onClick(name)}>
      <div className="flex flex-column">
        <h6>{name}</h6>
        {isSubtitle && <p className="subtitle">Easy-to-use browser extension</p>}
      </div>
      <span className="icon">{icon}</span>
    </Styles.Type>
  );
};

export { Type };
