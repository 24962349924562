import profile from 'assets/icons/svg/profile.svg';
import reimburser from 'assets/icons/svg/reimburser.svg';
import storeFront from 'assets/icons/svg/store-front.svg';
import marketPlace from 'assets/icons/svg/market-place.svg';
import wrappedNFTs from 'assets/icons/svg/wrapped-NFTs.svg';
import listedNFTs from 'assets/icons/svg/listed-NFTs.svg';
import generator from 'assets/icons/svg/generator.svg';
import { allPaths } from './profile';
export const width = {
  lg: '238px',
};

export const menus = [
  {
    name: 'Profile',
    icon: profile,
    path: '/',
    allPaths,
  },
  {
    name: 'Storefront',
    icon: storeFront,
    children: [
      {
        name: 'Reimburser',
        icon: reimburser,
        path: '/reimburser',
      },
      {
        name: 'Generator',
        icon: generator,
        path: '/generator',
      },
    ],
  },
  {
    name: 'Marketplace',
    icon: marketPlace,
    children: [
      {
        name: 'Wrapped NFTs',
        icon: wrappedNFTs,
        path: '/nft/wrapped',
      },
      {
        name: 'Listed NFTs',
        icon: listedNFTs,
        path: '/nft/listed',
      },
    ],
  },
];
