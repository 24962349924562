import React from 'react';
import dollar from 'assets/images/dollar.svg';
import theme from 'themes/light-theme';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  dollar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
  },
  dollarPrice: {
    margin: 0,
    padding: 0,
    fontSize: 18,
    marginLeft: 5,
    fontWeight: 500,
    color: theme.primary4Shade,
  },
});
const DollarAmount = () => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.dollar}>
        <div className="dollar-icon">
          <img src={dollar} />
        </div>
        <p className={classes.dollarPrice}>2,000 USD</p>
      </div>
    </div>
  );
};

export { DollarAmount };
