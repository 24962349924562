import { makeStyles } from '@material-ui/core/styles';
import React, { useRef, useState } from 'react';
import lightTheme from 'themes/light-theme';
import drop from 'assets/icons/svg/drop-zone.svg';
import { dragOver, dragEnter, dragLeave, fileDrop, handleFiles } from 'util/dropzone';

const useStyles = makeStyles({
  root: {
    margin: '10px 10px 50px 10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  dropContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '2.5px dashed',
    borderColor: lightTheme.primary,
    borderRadius: 8,
    padding: '10px 20px',
    cursor: 'pointer',
  },
  supported: {
    color: lightTheme.gray,
  },
});

const DropZone = () => {
  const classes = useStyles();
  const inputFile = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleDropZoneClick = () => {
    inputFile.current.click();
  };
  const onImageUpload = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const files = event.target.files;
    await handleFiles([...files], setSelectedFiles, selectedFiles);
  };

  return (
    <div className={classes.root}>
      {selectedFiles.map((item, key) => (
        <React.Fragment key={key}>
          <div className="d-flex justify-content-start align-items-center">
            <img src={item.image} width="23px" height="23px" />
            <p className={`p-0 m-0 px-3 my-2 ${classes.supported}`}>{item.name}</p>
          </div>
        </React.Fragment>
      ))}

      <div
        className={classes.dropContainer}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={async (e) => {
          await fileDrop(e, setSelectedFiles, selectedFiles);
        }}
        onClick={handleDropZoneClick}
      >
        <input
          type="file"
          id="myfile"
          name="myfile"
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={async (e) => await onImageUpload(e)}
          multiple
        />
        <p className={`p-0 m-0`}>
          <img src={drop} width="23" height="23" className="mx-2" />
          Drop your files here, or <strong>browse</strong>
        </p>
        <p className={`${classes.supported} p-0 m-0`}>.png, .jpeg supported</p>
      </div>
    </div>
  );
};

export default DropZone;
