import React, { useState } from 'react';
import NavBar from './nav-bar';
import SideBar from './side-bar';
import { useMediaQuery } from '@material-ui/core';
import * as Styles from 'components/template/styles';

const Secure = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapse = () => setCollapsed((prev) => !prev);
  const matchesMobile = useMediaQuery('(max-width:600px)');
  return (
    <React.Fragment>
      {matchesMobile && (
        <React.Fragment>
          <SideBar
            matchesMobile={matchesMobile}
            toggleCollapse={toggleCollapse}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
        </React.Fragment>
      )}
      <NavBar
        matchesMobile={matchesMobile}
        collapsed={collapsed}
        toggleCollapse={toggleCollapse}
        blur={!collapsed && matchesMobile}
      />
      <div className="d-flex layout">
        {!matchesMobile && (
          <SideBar
            matchesMobile={matchesMobile}
            toggleCollapse={toggleCollapse}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
        )}
        <Styles.children
          className="px-3 py-2 w-100"
          blur={!collapsed && matchesMobile}
          onClick={() => {
            if (!collapsed && matchesMobile) {
              toggleCollapse();
            }
          }}
        >
          {children}
        </Styles.children>
      </div>
    </React.Fragment>
  );
};

export default Secure;
