/* eslint-disable react/jsx-filename-extension */
import MetaMask from 'assets/icons/svg/MetaMask.svg';
import WalletConnect from 'assets/icons/svg/WalletConnect.svg';
import CoinbaseWallet from 'assets/icons/svg/CoinbaseWallet.svg';

export const types = [
  {
    name: 'MetaMask',
    icon: <img src={MetaMask} />,
  },
  {
    name: 'WalletConnect',
    icon: <img src={WalletConnect} />,
  },
  {
    name: 'Coinbase Wallet',
    icon: <img src={CoinbaseWallet} />,
  },
];

export const steps = {
  WALLET: 'wallet',
  INITIALIZING: 'initializing',
  CONNECTED: 'connected',
  TRANSACTION_CONFIRMING: 'transaction_confirming',
  TRANSACTION_ERROR: 'transaction_error',
  TRANSACTION_CONFIRMED_OPENED: 'transaction_confirmed_opened',
  TRANSACTION_SUBMITTED: 'transaction_submitted',
};

export const modalTitles = {
  [steps.WALLET]: 'Connect Wallet',
  [steps.INITIALIZING]: '',
  [steps.CONNECTED]: 'Account',
  [steps.TRANSACTION_CONFIRMING]: '',
  [steps.TRANSACTION_ERROR]: 'Error',
  [steps.TRANSACTION_CONFIRMED_OPENED]: 'Account',
};
