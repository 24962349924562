import React from 'react';
import { ProfileHeader, CardContainer } from './components';

const Profile = ({ type }) => {
  return (
    <div>
      <ProfileHeader type={type} />
      <CardContainer type={type} />
    </div>
  );
};

export default Profile;
