import React from 'react';
import * as Styles from 'components/connect-wallet/styles';
import Loader from 'components/loader';

const Initializing = ({ onClick }) => {
  return (
    <Styles.Initializing className="py-2 px-3" onClick={onClick}>
      <Loader size={15} />
      <span>Initializing...</span>
    </Styles.Initializing>
  );
};

export { Initializing };
