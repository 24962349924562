import React, { useState } from 'react';
import TopHeader from 'components/top-header';
import { Details } from './components';
import { EmailShowDetails } from './components';

const Reimburser = () => {
  const [isShowDetails, setIsShowDetails] = useState(false);
  const toggleDetails = () => setIsShowDetails((prev) => !prev);
  return (
    <React.Fragment>
      <TopHeader title="Reimburser" />
      {!isShowDetails && <EmailShowDetails toggleDetails={toggleDetails} />}
      {isShowDetails && <Details />}
    </React.Fragment>
  );
};

export default Reimburser;
