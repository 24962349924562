import React from 'react';
import * as Styles from 'components/connect-wallet/styles';
import Error from 'assets/icons/svg/error-initializing.svg';
import { steps } from 'constants/wallet';

const TransactionError = ({ setActiveStep }) => {
  return (
    <Styles.TransactionError className="mt-4">
      <img src={Error} className="mb-2" />
      <h5 className="my-2">Transaction rejected</h5>
      <Styles.WhiteButton
        className="dismiss px-3 mt-3"
        onClick={() => {
          setActiveStep(steps.WALLET);
        }}
      >
        Dismiss
      </Styles.WhiteButton>
    </Styles.TransactionError>
  );
};

export { TransactionError };
