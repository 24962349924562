import React from 'react';
import * as Styles from 'components/connect-wallet/styles';
import error from 'assets/icons/svg/error-initializing.svg';
const ErrorInitializing = ({ onClick }) => {
  return (
    <Styles.ErrorInitializing className="py-2 px-3" onClick={onClick}>
      <div className="d-flex align-items-center">
        <img src={error} />
        <p className="mx-2">Error connecting</p>
      </div>
      <Styles.WhiteButton className="error-button">Try again</Styles.WhiteButton>
    </Styles.ErrorInitializing>
  );
};

export { ErrorInitializing };
