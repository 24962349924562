import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { header } from 'constants/profile';
import lightTheme from 'themes/light-theme';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: 100,
  },
  elements: {
    fontSize: 20,
    padding: '10px 20px',
    display: 'flex',
    cursor: 'pointer',
  },
  activePath: {
    background: lightTheme.grey3Shade,
    'border-radius': '10px',
  },
});

const ProfileHeader = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  return (
    <div style={{ overflow: 'auto' }}>
      <div className={classes.root}>
        {header.map((item, index) => (
          <div
            key={index}
            className={`${classes.elements} ${location.pathname === item.path && classes.activePath}`}
            onClick={() => history.push(item.path)}
          >
            <img src={item.image} style={{ marginRight: 10 }} height="30px" width="30px" /> {item.title}
          </div>
        ))}
      </div>
      <hr style={{ height: 1, color: 'black', opacity: 1 }} />
    </div>
  );
};

export { ProfileHeader };
