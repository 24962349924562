import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import chevron from 'assets/images/chevron.svg';
import chevronWhite from 'assets/images/chevronWhite.svg';
import { DollarAmount, Label } from './components';
import theme from 'themes/light-theme';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles({
  card: {
    width: 360,
    minHeight: 420,
    '@media (max-width: 650px)': {
      width: 300,
      minHeight: 350,
    },
    paddingTop: 20,
    paddingLeft: 30,

    borderRadius: 20,

    marginBottom: 30,
    marginRight: 10,
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25);',
  },
  name: {
    fontWeight: 500,
    paddingTop: 10,
    marginBottom: 10,
  },
  btn: {
    borderRadius: 22,
    width: 120,
    height: 35,
    paddingLeft: 11,
    backgroundColor: 'white',
    marginTop: -8,
    padding: 0,
    border: '1px solid #FAFBFF',
    marginLeft: 75,
  },
  link: {
    color: theme.primary4Shade,
    fontSize: 17,
    fontWeight: 500,
  },
  exclusive: {
    color: theme.grey2Shade,
    fontWeight: 500,
    fontSize: 17,
    marginBottom: 10,
  },
  dollar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
  },
  dollarPrice: {
    margin: 0,
    padding: 0,
    fontSize: 18,
    marginLeft: 5,
    color: theme.primary4Shade,
  },
  buy: {
    backgroundColor: theme.primary4Shade,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 9,
    color: 'white',
    padding: '2px 7px 2px 14px',
    border: 'none',
  },
  bottom: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: 25, marginBottom: 20 },
  image: { width: '90%' },
  sold: {
    opacity: 0.5,
  },
});

const CustomCard = ({ data, image, text, id, isBuy, isViewMore, isDollar, type, fullPageLink }) => {
  const classes = useStyles();
  const router = useHistory();
  return (
    <Card
      className={classes.card}
      onClick={() =>
        fullPageLink && router.push({ pathname: `/profile/${id}`, search: `?isBuy=${isBuy}&isDollar=${isDollar}` })
      }
      style={{ cursor: fullPageLink && 'pointer' }}
    >
      {isDollar && <DollarAmount />}
      <img src={image} className={classes.image} />
      <div>
        <h5 className={classes.name}> {text}</h5>
        <p className={classes.exclusive}>Exclusive</p>
        <div className={classes.bottom}>
          {isBuy && (
            <button className={`${classes.buy} ${data.sold && classes.sold}`}>
              Buy <img src={chevronWhite} className="mx-1" width="12" height="12" />
            </button>
          )}
          {isViewMore && (
            <Link to={`/profile/${id}`} style={{ textDecoration: 'none' }}>
              <p className={classes.link}>
                View More <img src={chevron} />
              </p>
            </Link>
          )}
          {type && <Label type={type} />}
        </div>
      </div>
    </Card>
  );
};

export default CustomCard;
