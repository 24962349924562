import All from 'assets/images/All.svg';
import Listed from 'assets/images/Listed.svg';
import berry from 'assets/images/berry.svg';
import hineCatone from 'assets/images/hineCatone.svg';
import pepito from 'assets/images/pepito.svg';
import lock from 'assets/icons/svg/lock.svg';
import primaryListed from 'assets/icons/svg/primary-listed.svg';

export const allPaths = ['/', '/wrapped', '/listed'];

export const header = [
  {
    image: All,
    title: 'All',
    path: allPaths[0],
  },
  {
    image: Listed,
    title: 'Wrapped',
    path: allPaths[1],
  },
  {
    image: Listed,
    title: 'Listed',
    path: allPaths[2],
  },
];

export const cardData = [
  { image: pepito, text: 'PepitoTheMascot', id: '5' },
  { image: hineCatone, text: 'HineCatone', id: '2', type: 'Wrapped', sold: true },
  { image: berry, text: 'CyberBerry', id: '3', type: 'Listed' },
  { image: berry, text: 'CyberBerry', id: '1', type: 'Wrapped' },
  { image: pepito, text: 'PepitoTheMascot', id: '4', type: 'Listed' },
  { image: hineCatone, text: 'HineCatone', id: '6' },
];

export const handleData = (type) => {
  if (!type) {
    return cardData;
  }
  return cardData.filter((item) => item.type === type);
};

export const typesData = {
  Wrapped: {
    icon: lock,
    height: 20,
    width: 20,
  },
  Listed: {
    icon: primaryListed,
    height: 31,
    width: 31,
  },
};
