import styled from 'styled-components';

export const TopHeader = styled.div`
  width: 100%;
  h2 {
    color: ${(props) => props.theme.primary};
  }
  hr {
    opacity: 1;
    height: 1.3px;
  }
`;
