import styled from 'styled-components';

export const Button = styled.div`
  color: white;
  .MuiButtonBase-root {
    filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.1));
    background: ${(props) => props.color || props.theme.buttons.primary};
    border-radius: ${(props) => props.borderRadius || '10px'};
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
    text-transform: none;
    padding: 0px 16px 0px 16px;
    color: white;
    &:hover {
      background: ${(props) => props.color};
    }
    .icon {
      margin-right: 10px;
      height: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 12px;
    }
  }
`;
