import TopHeader from 'components/top-header';
import React from 'react';
import { Grid } from '@material-ui/core';
import CustomCard from 'components/custom-card';
import { handleData } from 'constants/profile';

const Wrapped = ({ type, title }) => {
  return (
    <div>
      <TopHeader title={title} />
      <Grid container wrap="wrap" justifyContent="center">
        {handleData(type).map((card, index) => (
          <Grid item key={index}>
            <CustomCard
              data={card}
              image={card.image}
              text={card.text}
              id={card.id}
              type={card.type}
              isBuy
              isDollar
              fullPageLink
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Wrapped;
