import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import icon from 'assets/images/pageNotFound.svg';
import lightTheme from 'themes/light-theme';

const useStyles = makeStyles({
  headerLine: {
    marginTop: 50,
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column',
    height: '70vh',
  },
  image: {
    '@media (max-width: 600px)': {
      height: 150,
      width: 150,
    },
  },
  content: {
    textAlign: 'center',
    marginTop: 20,
  },
  p1: {
    fontSize: 24,
    color: lightTheme.primary,
    fontWeight: 500,
    margin: 4,
    '@media (max-width: 600px)': {
      fontSize: 30,
    },
  },
  p2: {
    fontSize: 18,
    color: lightTheme.grey2Shade,
    margin: 2,
    '@media (max-width: 600px)': {
      fontSize: 20,
    },
  },
  line: {
    color: lightTheme.grey2Shade,
    width: '20%',
    '@media (max-width: 768px)': {
        width: 260,
      },
  },
  description: {
    textAlign: 'center',
    color: lightTheme.primary,
    fontSize: 30,
    marginTop: 10,
  },
  d: {
    fontSize: 20,
    color: lightTheme.primary,
    fontWeight: 500,
    '@media (max-width: 600px)': {
      fontSize: 18,
    },
  },
  btn: {
    padding: '4px 24px',
    border: '2px solid',
    borderColor: lightTheme.primary,
    'box-sizing': 'border-box',
    borderRadius: 14,
    color: 'white',
    fontWeight: 500,
    cursor: 'pointer',
    backgroundColor: lightTheme.primary,
    outline: 'none',
  },
});

const PageNotFound = () => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.headerLine}>
        <hr style={{ height: 2, color: 'black', opacity: 1 }} />
      </div>
      <div className={classes.flex}>
        <div>
          <img src={icon} className={classes.image} />
        </div>
        <div className={classes.content}>
          <h6 className={classes.p1}>PAGE NOT FOUND</h6>
          <h6 className={classes.p2}>Error 404</h6>
        </div>
        <hr className={classes.line} style={{height:2.2}}/>
        <div className={classes.description}>
          <h6 className={classes.d}>
            Looks like you are trying to access a page that does not exist. <br />
            Proceed to the main page?
          </h6>
        </div>
        <button className={classes.btn}>Main Page</button>
      </div>
    </div>
  );
};

export default PageNotFound;
