import TopHeader from 'components/top-header';
import React, { useState } from 'react';
import { Fungible, NonFungible, FungibleBtn } from './components';

const generator = () => {
  const [activeTab, setActiveTab] = useState('first');
  return (
    <div>
      <TopHeader title="Generator" />
      <FungibleBtn activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab === 'first' && <Fungible />}
      {activeTab === 'second' && <NonFungible />}
    </div>
  );
};

export default generator;
