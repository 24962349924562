import styled from 'styled-components';

export const Loader = styled.div`
  border: ${(props) => (props.size ? `${props.size / 10}px solid grey` : '1.5px solid grey')};
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border-top: ${(props) => (props.size ? `${props.size / 10}px solid white` : '1.5px solid white')};
  border-radius: 50%;
  width: ${(props) => (props.size ? `${props.size}px` : '15px')};
  height: ${(props) => (props.size ? `${props.size}px` : '15px')};
  margin-right: 10px;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
