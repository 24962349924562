export default {
  /** Brand colors */
  primary: '#171C28',
  primary2Shade: '#2E3444',
  primary3Shade: '#212634',
  primary4Shade: '#05325B',
  buttons: {
    primary: 'linear-gradient(180deg, rgba(23, 28, 40, 0.75) 0%, #171c28 81.77%)',
  },
  error: '#FA4747',
  gray: '#ACB0BC',
  blue: '#0073DE',
  grey2Shade: '#777D8B',
  grey3Shade: 'rgba(48, 71, 91, 0.1)',
  grey4Shade: 'rgba(23, 28, 40, 0.8)',
};
