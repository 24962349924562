import React from 'react';
import { Type } from './index';
import { types } from 'constants/wallet';

const TypeDetails = ({ setWallet, setIsInitializing }) => {
  return (
    <div className="d-flex flex-column">
      {types.map((item, index) => (
        <Type
          name={item.name}
          icon={item.icon}
          key={index}
          onClick={(wallet) => {
            setWallet(wallet);
            setIsInitializing();
          }}
        />
      ))}
    </div>
  );
};

export { TypeDetails };
