import totalSupply from 'assets/icons/svg/total-supply.svg';

export const balanceDetail = [
  {
    price: '50,000',
    subtitle: 'Total Supply',
    icon: totalSupply,
  },
  {
    price: '50,000',
    subtitle: 'Total Supply',
    icon: totalSupply,
  },
  {
    price: '50,000',
    subtitle: 'Total Supply',
    icon: totalSupply,
  },
  {
    price: '50,000',
    subtitle: 'Total Supply',
    icon: totalSupply,
  },
];
