import React from 'react';
import { Menu } from '@material-ui/icons';
import * as Styles from 'components/template/styles';

const Header = ({ toggleCollapse, collapsed }) => {
  return (
    <Styles.Header collapsed={collapsed}>
      <div className="icon mx-2" onClick={toggleCollapse}>
        <Menu fontSize="large" />
      </div>
      <hr className={!collapsed && 'mx-2'} />
    </Styles.Header>
  );
};

export { Header };
