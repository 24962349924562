import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomButton from 'components/button';
import lightTheme from 'themes/light-theme';
import TextInput from 'components/text-input';

const useStyles = makeStyles({
  center: {
    '@media (max-width: 600px)': {
      textAlign: 'center',
    },
    margin: '10px 10px',
  },
});

const Fungible = () => {
  const classes = useStyles();
  return (
    <div className={classes.center}>
      <TextInput placeholder="Name" width="100%" />
      <br />
      <TextInput placeholder="Symbol" width="100%" />
      <br />
      <TextInput placeholder="Total Supply" width="100%" />
      <br />
      <CustomButton color={lightTheme.primary}>Create Token</CustomButton>
    </div>
  );
};

export { Fungible };
