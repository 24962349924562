export const dragOver = (e) => {
  e.preventDefault();
};

export const dragEnter = (e) => {
  e.preventDefault();
};

export const dragLeave = (e) => {
  e.preventDefault();
};

export const fileDrop = async (e, setFile, selectedFiles) => {
  e.preventDefault();
  const files = e.dataTransfer.files;
  if (files.length) {
    await handleFiles(files, setFile, selectedFiles);
  }
};

export const handleFiles = async (files, setFile) => {
  //   console.log(files);
  for (let i = 0; i < files.length; i++) {
    if (validateFile(files[i])) {
      const image = await fileToDataUri(files[i]);
      setFile((items) => [{ name: files[i].name, image }, ...items]);
      // add to an array so we can display the name of file
    } else {
      // add a new property called invalid
      // add to the same array so we can display the name of the file
      // set error message
      console.log({ error: files[i] });
      // add a new property called invalid
      files[i]['invalid'] = true;
    }
  }
};
// set error message

export const validateFile = (file) => {
  const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon'];
  if (validTypes.indexOf(file.type) === -1) {
    return false;
  }
  return true;
};

export const fileToDataUri = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });

// export const handleRenderImages = async (files, setFiles) => {
//   for (const file of files) {
//     const image = await fileToDataUri(file);
//     setFiles((oldFiles) => [{ name: file.name, image }, ...oldFiles]);
//   }
// };
