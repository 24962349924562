import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomButton from 'components/button';
import lightTheme from 'themes/light-theme';
import TextInput from 'components/text-input';
import { SuccessModal } from 'components/modals';
import DropZone from 'components/dropzone';

const useStyles = makeStyles({
  center: {
    '@media (max-width: 600px)': {
      textAlign: 'center',
    },
    margin: '10px 10px',
  },
  btn: {
    width: 160,
    height: 35,
    borderRadius: 12,
    outline: 'none',
    fontSize: 20,
  },
  selectInput: {
    height: 50,
    borderWidth: '0.3px',
    border: '2px solid #171C28',
    borderRadius: '9px',
    paddingLeft: 10,
    maxWidth: '300px',
    width: '100%',
    minWidth: '150px',
    fontSize: 18,
    // for remove the arrow
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
    //adding image of arrow along with position
    backgroundImage: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC')`,
    backgroundRepeat: ' no-repeat',
    backgroundPosition: '270px center',
    '@media (max-width: 350px)': {
      backgroundPosition: '240px center',
    },
  },
  input: {
    height: 40,
    borderWidth: '0.3px',
    border: '4px solid #171C28',
    borderRadius: '12px',
    fontSize: '20px',
    width: 350,
    padding: 12,
    '@media (max-width: 768px)': {
      width: 290,
      margin: 0,
    },
  },
  textArea: {
    borderRadius: 9,
    fontWeight: 400,
    maxWidth: '300px',
    width: '100%',
    lineHeight: '30px',
    minWidth: '150px',
    borderColor: lightTheme.primary,
    '&::placeholder': {
      opacity: 0.7,
    },
    '@media (max-width: 768px)': {
      marginLeft: 0,
    },
  },
  title: {
    fontSize: '2rem',
    textAlign: 'center',
    marginTop: '10%',
    color: '#4aa1f3',
    fontWeight: 'bold',
  },

  content: {
    backgroundColor: 'white',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const NonFungible = () => {
  const classes = useStyles();
  const [isSuccess, setIsSuccess] = useState(false);
  const toggle = () => setIsSuccess((prev) => !prev);
  return (
    <div className="d-flex align-items-end flex-wrap">
      <div className={classes.center}>
        <select className={`${classes.selectInput} my-2`} name="cars" id="cars">
          <option value="volvo">Classic NFT</option>
          <option value="saab">Saab</option>
          <option value="mercedes">Mercedes</option>
          <option value="audi">Audi</option>
        </select>
        <br />
        <TextInput placeholder="Name" width="100%" />
        <br />
        <TextInput placeholder="Author" width="100%" />
        <br />
        <textarea className={`${classes.textArea} my-2 py-2 px-2`} cols="38" rows="5" placeholder="Description" />

        <CustomButton color={lightTheme.primary} onClick={toggle}>
          Create NFT
        </CustomButton>
        <SuccessModal open={isSuccess} toggle={toggle} />
      </div>
      <DropZone />
    </div>
  );
};

export { NonFungible };
