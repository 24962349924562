import React from 'react';

const Balance = ({ price, subtitle, icon }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <h1>
        <strong>{price}</strong>
      </h1>
      <p>
        <strong>{subtitle}</strong>
      </p>
      <span className="icon">{icon}</span>
    </div>
  );
};

export { Balance };
