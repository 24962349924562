import styled from 'styled-components';

export const Tabs = styled.div`
  display: flex;
  border: 1px solid;
  padding: 0;
  border-color: ${(props) => props.theme.primary};
  box-sizing: border-box;
  border-radius: 10px;
  width: fit-content;
  overflow: hidden;
  cursor: pointer;
  .active {
    background-color: ${(props) => props.theme.primary};
    color: white;
    /* border-radius: 10px; */
  }
  @media screen and (max-width: 600px) {
    border-radius: 8px;
    .active {
      border-radius: 2px;
    }
  }
`;
