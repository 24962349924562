import React from 'react';
import * as Styles from './styles';
const TopHeader = ({ title }) => {
  return (
    <Styles.TopHeader>
      <h2 className="text-center py-2">{title}</h2>
      <hr />
    </Styles.TopHeader>
  );
};

export default TopHeader;
