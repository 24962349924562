import React from 'react';
import { Link } from 'react-router-dom';
import { MenuData } from './index';

const Menu = ({ name, icon, items = [], collapsed, path, customClass, paths }) => {
  return (
    <React.Fragment>
      {path ? (
        <Link to={path || '/'} style={{ color: 'white', textDecoration: 'auto' }}>
          <MenuData
            name={name}
            icon={icon}
            items={items}
            collapsed={collapsed}
            path={path}
            customClass={customClass}
            paths={paths}
          />
        </Link>
      ) : (
        <MenuData
          name={name}
          icon={icon}
          items={items}
          collapsed={collapsed}
          path={path}
          customClass={customClass}
          paths={paths}
        />
      )}
    </React.Fragment>
  );
};

export { Menu };
