/* eslint-disable unicorn/filename-case */
import Secure from 'components/template';
import { ThemeProvider } from 'styled-components';
import lightTheme from 'themes/light-theme';
import './assets/css/App.css';
import { Route, Switch } from 'react-router-dom';
import Profile from 'components/profile';
import Reimburser from 'components/reimburser';
import ProfileDetails from 'components/profile-details';
import Wrapped from 'components/nft';
import Generator from 'components/generator';
import PageNotFound from 'components/page-not-found';

function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <Secure>
        <Switch>
          <Route exact path="/" component={Profile} />
          <Route exact path="/wrapped" component={() => <Profile type="Wrapped" />} />
          <Route exact path="/listed" component={() => <Profile type="Listed" />} />
          <Route exact path="/profile/:id" component={ProfileDetails} />
          <Route exact path="/reimburser" component={Reimburser} />
          <Route exact path="/generator" component={Generator} />
          <Route exact path="/nft/wrapped" component={() => <Wrapped type="Wrapped" title="Wrapped NFTs"/>} />
          <Route exact path="/nft/listed" component={() => <Wrapped type="Listed" title="Listed NFTs"/>} />
          <Route exact path="/notfound" component={PageNotFound}/>
        </Switch>
      </Secure>
    </ThemeProvider>
  );
}

export default App;
