import React from 'react';
import * as Styles from './styles';

const TextInput = ({ placeholder, width, margin = true, ...remain }) => {
  return (
    <Styles.TextInput
      type="text"
      placeholder={placeholder}
      width={width}
      className={`py-2 px-2 ${margin && 'my-2'}`}
      {...remain}
    />
  );
};

export default TextInput;
