import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import back from 'assets/images/back.svg';
import { useParams } from 'react-router-dom';
import { cardData } from 'constants/profile';
import { CardForm, LockList } from './components';
import lightTheme from 'themes/light-theme';
import { useQuery } from 'util/url';
import { DollarAmount } from 'components/custom-card/components';
import CustomButton from 'components/button';

const ProfileDetails = () => {
  const { id } = useParams();
  const [isLock, setIsLock] = useState();
  const useStyles = makeStyles({
    root: {
      marginTop: 20,
    },
    image: {
      width: 400,
      '@media (max-width: 650px)': {
        width: 350,
      },
      '@media (max-width: 350px)': {
        width: 300,
      },
    },
    btn: {
      padding: '4px 24px',
      backgroundColor: isLock ? lightTheme.grey4Shade : lightTheme.primary,
      border: '2px solid',
      borderColor: lightTheme.primary,
      'box-sizing': 'border-box',
      borderRadius: 14,
      color: 'white',
      marginRight: 5,
      fontWeight: 500,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      marginTop: 12,
    },
    heading: {
      fontSize: 28,
      marginTop: 12,
      marginBottom: 0,
      color: lightTheme.primary,
      '@media (max-width: 768px)': {
        fontSize: 18,
      },
    },
    content: {
      fontSize: 25,
      color: lightTheme.grey2Shade,
      marginBottom: 0,
      fontWeight: 500,
      '@media (max-width: 768px)': {
        fontSize: 16,
      },
    },
    fontColor: {
      color: lightTheme.primary,
      '@media (max-width: 768px)': {
        fontSize: 24,
      },
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
    },
  });

  const card = cardData.find((item) => item.id === id);
  const classes = useStyles();
  const router = useHistory();
  const query = useQuery();
  return (
    <div>
      <img src={back} style={{ marginTop: 12, marginLeft: 15, cursor: 'pointer' }} onClick={() => router.goBack()} />
      <hr style={{ height: 1.5, color: 'black', opacity: 1 }} />
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={12} lg={7} className={classes.center}>
          <img src={card.image} className={classes.image} />
        </Grid>
        <Grid item sm={12} lg={5}>
          <div style={{ margin: '30px 30px 0px 0px' }}>
            <div className="d-flex">
              {query.get('isBuy') == 'true' ? (
                <div className="d-flex justify-content-between w-100">
                  <DollarAmount />
                  <CustomButton color={lightTheme.primary} style={{ opacity: card.sold ? 0.5 : 1 }}>
                    {card.sold ? 'Sold' : 'Buy'}
                  </CustomButton>
                </div>
              ) : (
                <LockList isLock={isLock} setIsLock={setIsLock} classes={classes} />
              )}
            </div>
            <hr style={{ height: 2, color: lightTheme.primary, opacity: 1 }} />
            <h2 className={classes.fontColor}> {card.text} </h2>
            <p className={classes.content}>Exclusive</p>
            <p className={classes.heading}>Description</p>
            <p className={classes.content}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            </p>
            <p className={classes.heading}>Owner</p>
            <p className={classes.content}>0xf537...9F18</p>
          </div>
        </Grid>
        {isLock && <CardForm setIsLock={setIsLock} />}
      </Grid>
    </div>
  );
};

export default ProfileDetails;
