import React, { useEffect } from 'react';
import { menus } from 'constants/side-bar';
import { Header, Menu } from './components';
import * as Styles from 'components/template/styles';
const SideBar = ({ matchesMobile, setCollapsed, toggleCollapse, collapsed }) => {
  useEffect(() => {
    if (matchesMobile) {
      setCollapsed(true);
    }
  }, [matchesMobile]);
  return (
    <Styles.SidebarContainer className="pt-2" collapsed={collapsed} matchesMobile={matchesMobile}>
      {!(collapsed && matchesMobile) && (
        <React.Fragment>
          <Header toggleCollapse={toggleCollapse} collapsed={collapsed} />
          {menus.map((item, index) => (
            <Menu
              name={item.name}
              icon={<img src={item.icon} />}
              path={item.path}
              key={index}
              items={item.children}
              collapsed={collapsed}
              paths={item.allPaths}
            />
          ))}
        </React.Fragment>
      )}
    </Styles.SidebarContainer>
  );
};

export default SideBar;
